import { Text } from "preact-i18n";
import classNames from "classnames";
import usePostMessage from "./../../hooks/post-message";

import "../../style/ACHPaymentInitiatedSection.css";

const ACHPaymentInitiatedSection = ({
  transaction,
  transactionId,
  isDesktopOrLaptop,
  payLink,
  amount,
  businessName,
}) => {
  const isInvoice = payLink.urlType === "N";
  const invoiceId = payLink.invoice?.customInvoiceId || payLink.invoice?.orderId?.slice(0, 8);
  const isRecurringInvoice = isInvoice && Boolean(payLink?.invoice?.recurringPlan);
  const invoiceRecurrenceFrequencyInterval = payLink?.invoice?.recurringPlan?.frequencyInterval;
  const invoiceRecurrenceFrequencyCount = payLink?.invoice?.recurringPlan?.frequencyCount;
  const recurrenceRateLookup = {
    DAY: "daily",
    WEEK: "weekly",
    MONTH: "monthly",
    YEAR: "yearly",
  };
  const invoiceRecurrenceRate =
    invoiceRecurrenceFrequencyCount > 1
      ? `every ${invoiceRecurrenceFrequencyCount} ${invoiceRecurrenceFrequencyInterval?.toLowerCase()}s`
      : recurrenceRateLookup[invoiceRecurrenceFrequencyInterval];
  const bankAccountHolder = transaction?.fundingSource?.bankAccount?.ownerName;
  const bankName = transaction?.fundingSource?.bankAccount?.bankName;
  const bankRoutingNumber = transaction?.fundingSource?.bankAccount?.routingNumber;
  const bankAccountNumberLastFour = transaction?.fundingSource?.bankAccount?.accountNumberLastFour;
  const createdAtDate = new Date(transaction?.createdAt)?.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const entryDate = new Date(transaction?.voidableUntil)?.toLocaleDateString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const paymentMethodDetails = [
    {
      id: "account-holder",
      heading: "Account Holder",
      value: bankAccountHolder,
    },
    {
      id: "bank-name",
      heading: "Bank Name",
      value: bankName,
    },
    {
      id: "routing-number",
      heading: "Routing Number",
      value: bankRoutingNumber,
    },
    {
      id: "account-number",
      heading: "Account Number",
      value: bankAccountNumberLastFour,
    },
    {
      id: "entry-date",
      heading: "Entry Date",
      value: entryDate,
    },
  ];
  const endingPageAchClassNames = classNames({
    "ending-page-mobile-ach": !isDesktopOrLaptop,
    "ending-page-section-ach": true,
  });

  return (
    <div className={endingPageAchClassNames}>
      <div className="ok-filled-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none">
          <path
            fill="#00A63F"
            fill-rule="evenodd"
            d="M7.972 3.489A16.25 16.25 0 0 1 17 .75 16.269 16.269 0 0 1 33.25 17 16.25 16.25 0 1 1 7.972 3.489Zm7.361 16.743 7.45-7.45a1.25 1.25 0 0 1 1.767 1.768l-8.333 8.334a1.26 1.26 0 0 1-.884.366 1.26 1.26 0 0 1-.883-.366l-5-5a1.25 1.25 0 0 1 1.767-1.768l4.116 4.116Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div className="ending-page-ach-header-section">
        <div className="ending-page-ach-header">
          <Text id="ENDING_PAGE_ACH_HEADER">Payment initiated</Text>
        </div>
        <div className="ending-page-ach-description">
          <Text id="ENDING_PAGE_ACH_DESCRIPTION" fields={{ businessName, amount }}>
            You have authorized {businessName} to withdraw {amount} from your account. The funds
            will be withdrawn in 1 to 2 business days.
          </Text>
        </div>
      </div>

      {isInvoice ? (
        <div className="ending-page-ach-invoice-section">
          <div className="ending-page-ach-invoice-id">
            <Text id="ENDING_PAGE_ACH_INVOICE_ID" fields={{ invoiceId }}>
              Invoice #{invoiceId}
            </Text>
          </div>
          <div className="ending-page-ach-invoice-entry-date">
            <Text id="ENDING_PAGE_ACH_INVOICE_ENTRY_DATE">{createdAtDate}</Text>
          </div>
          {isRecurringInvoice ? (
            <div className="ending-page-ach-invoice-recurrence">
              <Text id="ENDING_PAGE_ACH_INVOICE_RECURRENCE" fields={{ invoiceRecurrenceRate }}>
                Recurring ({invoiceRecurrenceRate})
              </Text>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="ending-page-ach-payment-method-section">
        <div className="ending-page-ach-payment-method-header">
          <Text id="ENDING_PAGE_ACH_PAYMENT_METHOD_HEADER">Payment Method (ACH)</Text>
        </div>
        <div className="ending-page-ach-payment-method-details">
          {paymentMethodDetails?.map((detail) => {
            const idFormatted = detail?.id?.toUpperCase()?.replace("-", "_");
            const heading = detail?.heading;
            const value = `${detail?.id === "account-number" ? "*****" : ""}${detail?.value}`;
            return (
              <div
                key={detail?.id}
                className={`ending-page-ach-payment-method-details-${detail?.id}`}
              >
                <div>
                  <Text
                    id={`ENDING_PAGE_ACH_PAYMENT_METHOD_DETAILS_${idFormatted}_HEADING`}
                    fields={{ heading }}
                  >
                    {heading}
                  </Text>
                </div>
                <div>
                  <Text
                    id={`ENDING_PAGE_ACH_PAYMENT_METHOD_DETAILS_${idFormatted}_VALUE`}
                    fields={{ value }}
                  >
                    {value}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
        <div className="ending-page-ach-payment-method-entry-date-description">
          <Text id="ENDING_PAGE_ACH_PAYMENT_METHOD_ENTRY_DATE_DESCRIPTION">
            Entry dates that fall on a weekend or holiday will be processed on the next banking day.
          </Text>
        </div>
      </div>

      {isRecurringInvoice ? (
        <div className="ending-page-ach-amend-or-cancel-description">
          <Text id="ENDING_PAGE_ACH_AMEND_OR_CANCEL_DESCRIPTION" fields={{ businessName }}>
            You may amend or cancel this authorization at any time by providing notice to{" "}
            {businessName} with 30 (thirty) days notice.
          </Text>
        </div>
      ) : null}

      <div className="ending-page-ach-transaction-id">
        <Text id="ENDING_PAGE_ACH_TRANSACTION_ID" fields={{ transactionId }}>
          Transaction ID {transactionId}
        </Text>
      </div>
      {usePostMessage("pay-buttons-js", "setCheckoutComplete", { transaction })}
    </div>
  );
};

export default ACHPaymentInitiatedSection;
