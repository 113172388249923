/**
 * Single page pay link checkout
 * Used for Fixed, Custom amount, Single-select list pay links
 */

import { useContext, useRef, useEffect, useState } from "preact/hooks";
import { route } from "preact-router";

import { Configs } from "../../helpers/context";
import {
  getWalletCollectOptions,
  getEcommerceTransactionOptions,
  WALLET_COLLECT_OPTIONS,
  validateOrder,
} from "../../helpers/collect";
import { formatIntegerCurrencyDecimal } from "../../helpers/formatters";
import { showApplePayButton } from "../../helpers/applePay";
import { EMIT_SUCCESS } from "../../helpers/event-definitions";
import { getOrderTotal } from "../../helpers/order";

import { charge } from "../../rest/charge";
import { calculateSurchargeFee } from "../../rest/fees";

const WalletCollect = ({
  payLink,
  order,
  product,
  setTransaction,
  setSurchargeData,
  setShowSurchargeConfirmation,
  onCollectError,
  showError,
  setCharging,
  trackCustomEvent,
}) => {
  const configsContext = useContext(Configs);
  const [isMounted, setIsMounted] = useState(false);
  // global wallet support status
  // used to verify we've checked for all supported wallets before mounting
  const [isSupported, setIsSupported] = useState(false);

  // use a ref for permanent component state
  // that shouldn't trigger rerender on change
  const collectRef = useRef();

  const startSession = (source, walletUpdate) => {
    if (source === 'google_pay') {
      collectRef?.current?.startGooglePaySession(walletUpdate);
      return;
    }

    collectRef?.current?.startApplePaySession(walletUpdate);
  }

  /**
   * Triggered when payment is authorized (aka nonce is ready)
   * @param {Event} event
   */
  async function onPaymentAuthorized(event) {
    try {
      // if nonce not returned, fail
      if (!event.nonce) {
        throw new Error();
      }

      showError(false);
      setCharging(true);

      // attach businessId
      event.businessId = configsContext?.configs?.businessId;

      const chargeOptions = {
        ...getEcommerceTransactionOptions(configsContext?.configs, payLink, order, product, event),
        nonce: event.nonce,
        emailAddress: event.shippingAddress?.emailAddress || event.billingAddress?.emailAddress,
        phone: event.shippingAddress?.phoneNumber || event.billingAddress?.phoneNumber,
      };

      // calculate surcharge fee
      if (payLink.isSurchargeFeatureEnabled) {
        const surchargeFee = await calculateSurchargeFee(configsContext?.configs?.env, chargeOptions);

        if (surchargeFee?.amount > 0) {
          setSurchargeData({ surchargeFee, chargeOptions, event });
          setShowSurchargeConfirmation(true);

          setCharging(false);

          event.complete();

          return;
        }
      }

      // create transaction
      const transaction = await charge(configsContext?.configs?.env, chargeOptions);

      // Metrics.track(MetricEvents.ORDER_COMPLETED_POYNT_APPLE_PAY_IMPRESSION);
      event.complete();

      setCharging(false);
      setTransaction(transaction);

      if (trackCustomEvent) {
        trackCustomEvent(EMIT_SUCCESS);
      }
      route("/transaction-complete");
    } catch (error) {
      console.log("payment authorize error", error);

      const walletError = {
        code: "invalid_payment_data",
        message: "Unable to process payment",
      };

      event.complete({ error: walletError });

      setCharging(false);

      showError("Card is invalid. Please try again.");
    }
  }

  /**
   * Triggers on wallet pay button click
   * @param {Event} event
   */
  function onWalletPayClick(e) {
    const walletUpdate = {
      total: {
        label: "TOTAL",
        amount: formatIntegerCurrencyDecimal(getOrderTotal(order)),
      },
    };

    const error = validateOrder(configsContext?.configs, payLink, order);
    if (error) {
      showError(error);
      throw new Error(error);
    }

    try{
      startSession(e.source, walletUpdate);
    } catch (error) {
      console.log(`session error - ${e.source}`, error);
    }
  }

  /**
   * Triggers on shipping address/method change 
   * @param {Event} event
   */
  function onShippingAddressChange(e) {
    const options = {
      shippingMethods: [],
    }

    e.updateWith(options);
  }

  useEffect(() => {
    const mountWallets = async () => {
      const collect = new TokenizeJs(
        configsContext?.configs?.businessId,
        payLink.applicationId,
        getWalletCollectOptions(configsContext?.configs, payLink, order),
      );

      collect.on("payment_authorized", onPaymentAuthorized);
      collect.on("shipping_address_change", onShippingAddressChange);
      collect.on("error", onCollectError);

      collectRef.current = collect;

      try {
        // get what wallet providers are supported
        const result = await collect.supportWalletPayments();

        console.log("wallet support", result);

        // update global config with supportGooglePay since other pages use it for styling
        if (result?.googlePay) {
          configsContext?.setConfig("supportGooglePay", true);
          WALLET_COLLECT_OPTIONS.paymentMethods.push("google_pay");
        }

        // verify domain registration only when apple pay is supported
        if (result?.applePay) {
          const show = await showApplePayButton(
            configsContext?.configs?.env,
            configsContext?.configs?.businessId,
          );

          console.log("show apple pay", show);

          if (show.showApplePay) {
            // update global config with supportApplePay since other pages use it for styling
            configsContext?.setConfig("supportApplePay", true);
            WALLET_COLLECT_OPTIONS.paymentMethods.push("apple_pay");
          }
        }

        if (WALLET_COLLECT_OPTIONS.paymentMethods.length) {
          collect.mount("wallet-payments-container", document, {
            ...WALLET_COLLECT_OPTIONS,
            buttonOptions: {
              ...WALLET_COLLECT_OPTIONS.buttonOptions,
              onClick: onWalletPayClick,
              type: (WALLET_COLLECT_OPTIONS.paymentMethods.length > 1 ? 'plain' : 'pay'),
            },
          });
        }
      } catch (err) {
        // do nothing, just don't turn on apple pay
        console.warn("Error checking wallet support", err);
      }
    };

    mountWallets();
  }, []);

  return <div id="wallet-payments-container" />;
};

export default WalletCollect;
